import { template as template_8d174dbe8abe43b599a1c5b0776d8fd0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8d174dbe8abe43b599a1c5b0776d8fd0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
